<template>
  <v-container fluid>
    <v-card>
        <v-card-title>Downloads</v-card-title>
        <v-card-text>
        <select-artist class="py-2"></select-artist>
        <v-text-field
        class="py-2"
          v-model="startFrom"
          label="Start From"
          dense
        ></v-text-field>
        <v-btn
          color="success"
          class="mr-4"
          @click="downloadProductsForXero()"
        >
          Download for Xero {{startFrom}} - {{Number(startFrom) + 999}}
        </v-btn>
        <!-- <v-btn
          color="success"
          class="mr-4"
          @click="downloadProductsForXero()"
        >
          Download for Xero
        </v-btn> -->
        <v-btn
          color="success"
          class="mr-4"
          @click="downloadProducts(false)"
        >
          Download Products
        </v-btn>
        <!-- <v-btn
          color="success"
          class="mr-4"
          @click="downloadProducts(true)"
        >
          Download Artist Template
        </v-btn> -->
        </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Product from '@/api/admin/product'
import { mapState, mapGetters } from 'vuex'
import xlsx from 'xlsx'
import SelectArtist from '@/components/SelectArtist'
import _ from 'lodash'

export default {
  components: {
    SelectArtist
  },
  async created () {},
  computed: mapState({
    products: state => state.products.products,
    ...mapGetters('reports', ['activeArtist']),
    // artists: state => state.products.artists
  }),
  data () {
    return {
      startFrom: 0,
      selectedArtist: 'All'
    }
  },
  watch: {},
  methods: {
    downloadProducts (arg) {
      const filter = this.activeArtist === 'All' ? {} : { artistCode: this.activeArtist }
      const templateOnly = arg
      Product.loadArtistProducts(filter).then(products => {
        const preparedData = products.data.map((item) => {
          delete item._id
          delete item.id
          delete item.longDesc
          delete item.createdAt
          delete item.updatedAt
          delete item.__v
          return { ...item }
        })
        const finalData = templateOnly ? _.uniqBy(preparedData, 'category') : preparedData

        const workbook = xlsx.utils.book_new()
        const worksheet = xlsx.utils.json_to_sheet(finalData)
        xlsx.utils.book_append_sheet(workbook, worksheet, 'Products')
        xlsx.writeFile(workbook, 'PRODUCTS-ALL.xlsx')
      })
    },
    downloadProductsForXero () {
      const filter = this.activeArtist === 'All' ? {} : { artistCode: this.activeArtist }
      Product.loadProductsForXero({ skip: this.startFrom, limit: 1000, ...filter }).then(products => {
        const workbook = xlsx.utils.book_new()
        const worksheet = xlsx.utils.json_to_sheet(products.data)
        xlsx.utils.book_append_sheet(workbook, worksheet, 'Products')
        xlsx.writeFile(workbook, `PRODUCTS-${this.startFrom}-${Number(this.startFrom) + 999}.xlsx`)
        // xlsx.writeFile(workbook, `PRODUCTS-${this.startFrom}-${Number(this.startFrom) + 999}.csv`, {bookType: 'csv'})
      })
    },
    reset () {}
  }
}
</script>
